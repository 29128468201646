export function imgChange() {
  const width = $(window).width();

  if (width < 769) {
    $('.js-toggleImg').each(function () {
      $(this).attr('src', $(this).attr('src').replace('_pc', '_sp'));
    });
  } else {
    $('.js-toggleImg').each(function () {
      $(this).attr('src', $(this).attr('src').replace('_sp', '_pc'));
    });
  }
}
