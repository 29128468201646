const $overlay = $('.js-overlay');
const $modal = $('.js-modal');
const $modalTrigger = $('.js-modalTrigger');
const $modalClose = $('.js-modalClose');
const $movie = $('.js-movie');

export function modal() {
  openModal();
  closeModal();
}

function openModal() {
  $modalTrigger.on('click', function () {
    const movieId = $(this).attr('data-movie');

    // youtubeの動画idを反映しつつappend
    $movie.append('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + movieId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');

    // オーバーレイとモーダルの表示
    $overlay.addClass('is-view');
    $modal.addClass('is-view');
  });
}

function closeModal() {
  $modalClose.on('click', function () {
    // オーバーレイとモーダルの表示
    $overlay.removeClass('is-view');
    $modal.removeClass('is-view');

    // iframeの削除
    $movie.find('iframe').remove();
  });
}
