const $tabTrigger = $('.js-tabTrigger');
const $tabBox = $('.js-tabBox');
const $tabActive = $('.js-tabBox.is-active');

export function tabChange() {
  // 初期表示タブを選択
  const hashString = location.hash.substr(1);

  $tabTrigger.each(function () {
    if (hashString === $(this).attr('data-tabnum')) {
      // tab内の表示を更新
      tabResult($(this));
    }
  });

  $tabTrigger.on('click', function () {
    // tab内の表示を更新
    tabResult($(this));
  });
}

// tab内の表示を更新
function tabResult(ele) {
  const tabData = ele.attr('data-tabnum');

  // tabのアクティブ変更
  $tabTrigger.removeClass('is-active');
  ele.addClass('is-active');

  // 対応したtabに表示切り替え
  $tabBox.removeClass('is-active');
  $tabBox.each(function () {
    if (tabData === $(this).attr('data-tabbox')) {
      $(this).addClass('is-active');
    }
  });
}
