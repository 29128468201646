import Swiper from 'swiper';

export function swiper() {
  const productSwiper = new Swiper('.js-productSwiper', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // 768px以上の場合
      769: {
        slidesPerView: 3,
      },
    },
  });
}

export function itemSwiper() {
  const itemSwiper = new Swiper('.js-itemSwiper', {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
  });
}

export function kvSwiper() {
  const kvSwiper = new Swiper('.js-kvSwiper', {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });
}

export function newsTicker() {
  const newsTicker = new Swiper('.js-newsTicker', {
    direction: 'vertical',
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  });

  const target = document.querySelector('.js-newsTicker');

  if (target) {
    target.addEventListener('mouseenter', function (e) {
      newsTicker.autoplay.stop();
    });

    target.addEventListener('mouseleave', function (e) {
      newsTicker.autoplay.start();
    });
  }
}

export function spSlider() {
  window.addEventListener(
    'DOMContentLoaded',
    () => {
      if (document.querySelector('.js-sliderSp')) {
        // option は使い回すので別に書く
        const options = {
          effect: 'fade',
          loop: true,
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        };
        const breakPoint = 767;
        let spSwiper;
        if (window.innerWidth <= breakPoint) {
          spSwiper = new Swiper('.js-sliderSp', options);
        } else {
          spSwiper = undefined;
        }
        window.addEventListener(
          'resize',
          () => {
            if (window.innerWidth <= breakPoint) {
              if (spSwiper) return;
              spSwiper = new Swiper('.js-sliderSp', options);
            } else {
              if (!spSwiper) return;
              spSwiper.destroy();
              spSwiper = undefined;
            }
          },
          false
        );
      }
    },
    false
  );
}
