export function cardSelect() {
  const $cardTrigger = $('.js-cardTrigger');
  const $cardName = $('.js-cardName');
  const $cardSelectedBox = $('.js-cardSelectedBox');

  $cardTrigger.on('click', () => {
    const selectName = $(event.currentTarget).find('p').text();

    $cardName.text(selectName);
    $('html,body').animate({
      scrollTop: $cardSelectedBox.offset().top - $('.l-header').outerHeight(),
    });
  });
}
