export function select() {
  const $contactSelect = $('.p-contact_select select');

  $contactSelect.addClass('js-select');

  // selectと同じようにonchangeでラベルを変えたいときはJSを用いる
  $(document).on('change', '.js-select', function () {
    var $this = $(this);
    var $option = $this.find('option:selected');
    $('.js-selectText').text($option.text());
    // onchange後にフォーカスしてるのが嫌な場合
    $this.blur();
  });
}
