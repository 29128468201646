export function hamburger() {
  const $hamburgerTrigger = $('.js-hamburgerTrigger');
  const $hamburger = $('.js-hamburger');
  const $gnav = $('.js-gnav');

  $hamburgerTrigger.on('click', () => {
    $('body').toggleClass('is-fixed');
    $hamburger.toggleClass('is-active');
    $gnav.toggleClass('is-view');
  });
}
