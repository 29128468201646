export function imgSelect() {
  const $imgMain = $('.js-imgMain');
  const $imgItem = $('.js-imgItem');

  $imgItem.on('click', (event) => {
    const imgSrc = $(event.currentTarget).find('img').attr('src');

    $imgItem.removeClass('is-active');
    $(event.currentTarget).addClass('is-active');

    $imgMain.find('img').attr('src', imgSrc);
  });
}
