import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import { swiper, kvSwiper, itemSwiper, newsTicker, spSlider } from './modules/common/swiper';
import { modal } from './modules/common/modal';
import { imgChange } from './modules/common/imgChange';
import { smooth } from './modules/common/smooth';
import { select } from './modules/contact/select';
import { hamburger } from './modules/header/hamburger';
import { megaMenu } from './modules/header/megaMenu';
import { tabChange } from './modules/common/tabChange';
import { cardSelect } from './modules/common/cardSelect';
import { imgSelect } from './modules/product/imgSelect';
import { itemTippy } from './modules/product/itemTippy';

swiper();
kvSwiper();
newsTicker();
spSlider();
megaMenu();
tabChange();
cardSelect();
imgSelect();
itemTippy();

$(() => {
  smooth();
  modal();
  select();
  hamburger();
});

$(window).on('load', () => {
  imgChange();
  itemSwiper();
});

$(window).on('resize', _.throttle(imgChange, 100));
$(window).on('resize', _.throttle(itemSwiper, 100));
