const $megaTrigger = $('.js-megaTrigger');
const $mega = $('.js-mega');

export function megaMenu() {
  megaMenuOpen();
  megaMenuClose();
}

function megaMenuOpen() {
  $megaTrigger.on('mouseover', (event) => {
    $(event.currentTarget).find($mega).addClass('is-view');
  });
}

function megaMenuClose() {
  $megaTrigger.on('mouseleave', (event) => {
    $(event.currentTarget).find($mega).removeClass('is-view');
  });
}
