import tippy from 'tippy.js';

export function itemTippy() {
  const targets = document.querySelectorAll('.js-tippy');
  targets.forEach((element, index) => {
    tippy(`#${element.id}`, {
      allowHTML: true,
      content: `<div class="tooltip_content">${element.dataset.name}</div>`,
      appendTo: 'parent',
    });
  });
}
